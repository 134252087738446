import cn from 'classnames'
import {useCallback} from 'react'
import {RemixiconReactIconComponentType} from 'remixicon-react'
import styled from 'styled-components'
import {mainButtonCommonStyles} from './buttonCommonStyles'

const Btn = styled.button`
  width: fit-content;
  gap: 8px;
  font-weight: 500;
  padding-inline: 16px;

  &.large {
    height: 62px;
    font-size: 18px;
  }
  &.medium {
    height: 46px;
    font-size: 16px;
  }
  &.small {
    height: 34px;
    font-size: 16px;
  }
  &.fullWidth {
    width: 100%;
  }
  ${mainButtonCommonStyles()}
`

interface MainButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonVariant?:
    | 'primary'
    | 'secondary'
    | 'negative'
    | 'ghost'
    | 'ghostNegative'
  size?: 'small' | 'medium' | 'large'
  loading?: boolean
  label: string | React.ReactNode
  prefixIcon?: RemixiconReactIconComponentType
  suffixIcon?: RemixiconReactIconComponentType
  disabled?: boolean
  iconColor?: string
  fullWidth?: boolean
}

export default function MainButton({
  buttonVariant = 'primary',
  size = 'medium',
  loading = false,
  label,
  prefixIcon: PrefixIcon,
  suffixIcon: SuffixIcon,
  disabled = false,
  iconColor = 'currentColor',
  fullWidth = false,
  ...props
}: MainButtonProps) {
  const variantStyles = {
    primary: buttonVariant === 'primary',
    secondary: buttonVariant === 'secondary',
    negative: buttonVariant === 'negative',
    ghost: buttonVariant === 'ghost',
    ghostNegative: buttonVariant === 'ghostNegative',
  }

  const sizeStyles = {
    small: size === 'small',
    medium: size === 'medium',
    large: size === 'large',
  }

  const getIconSize = useCallback(() => {
    if (size === 'small') {
      return 16
    }
    if (size === 'medium') {
      return 20
    }
    return 24
  }, [size])

  return (
    <Btn
      disabled={disabled || loading}
      className={cn(variantStyles, sizeStyles, {loading, fullWidth})}
      {...props}
    >
      {PrefixIcon && <PrefixIcon size={getIconSize()} color={iconColor} />}
      {loading ? 'Augnablik ...' : label}
      {SuffixIcon && <SuffixIcon size={getIconSize()} color={iconColor} />}
    </Btn>
  )
}
